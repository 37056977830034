import axios from 'axios';
import React, { useEffect, useState } from 'react'
import BannerComponent from '../Components/Common/BannerComponent'
import BookAppointmentComponent from '../Components/Common/BookAppointmentComponent'
import siteSetting from '../services/settingServices';
import { linkUrl } from '../utilies/linkUrl';
import MessageBox from '../utilies/MessageBox';

const ContactPage = () => {
    const [contact, setContact] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);

    useEffect(()=>{
        window.scrollTo(0, 0);
    
        const settingData= async()=>{
            setIsLoading(true);
            const sitedata = await siteSetting()
            if(sitedata) {
                setContact(sitedata) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        settingData()
    },[])

    const submitValue=async(e)=>{
        e.preventDefault();
        setIsLoading(true)
        const contactForm = {
            name,
            email,
            subject,
            message
        }
        if (contactForm !== null) {
            try {
                const res = await axios.post(`${linkUrl}/contactus`, contactForm);
                setFullName("");
                setEmail("");

                setSubject("");
                setMessage("");

                if (res.data.status === 200) {

                    setIsLoading(false);
                    setSuccmesg("We Receive your message")
                    //  setOffers(res.data.data[0]);
                    setError(null);
                }

            } catch (err) {

                setError("Something went worng ");
                setIsLoading(false)
                // setOffers(null);
            }
            finally {
                setIsLoading(false);
            }
        }
        setFullName("");
        setEmail("");

        setSubject("");
        setMessage("");

    }
  return (
   <>
   <BannerComponent subTitle="Contact-Us" title="Contact Us"/>
  
   <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                <div className="container">
             
					<div className="row justify-content-center align-items-center">
						
                         <div className="col-lg-4 order-2">
                             
                            <div className="contact-info">
                                  <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-map"></i>
                                        </div>
                                        <h5>Office Address</h5>
                                        <p>  {contact && contact.site_address ? contact.site_address : ""}</p>
                                     
                                    </div>
                                     <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <h5>Working Hours</h5>
                                        <p>{contact && contact.site_mobile ? contact.site_mobile : ""} <br/> 
                                        {contact && contact.site_phone ? contact.site_phone : ""} 
</p>
                                    </div>
                                     <div className="single-cta wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                        <div className="f-cta-icon">
                                            <i className="far fa-envelope-open"></i>
                                        </div>
                                        <h5>Message Us</h5>
                                        <p>
                                        {contact && contact.site_email ? contact.site_email : ""}
                                        </p>
                                       
                                    </div>
                                </div>							
                        </div>
                        <div className="col-lg-8 order-1">
                            <div className="contact-bg02">
                                <div className="section-title center-align">
                                    <h2>
                                       Make Appointment
                                    </h2>
                                </div> 
                                {
                                        error ? (
                                            <MessageBox varient="danger">{error}</MessageBox>
                                        ) : ""

                                    }

                                    <>
                                        {
                                            succmesg ? (
                                                <MessageBox varient="success">{succmesg}</MessageBox>
                                            ) : ""

                                        }
                                    </>    

                                <form onSubmit={submitValue} className="contact-form mt-30">
                                    <div className="row">
                                    <div className="col-lg-6">
                                        <div className="contact-field p-relative c-name mb-20">                                    
                                            <input type="text" 
                                            name="name"
                                            value={name}
                                            onChange={e => setFullName(e.target.value)}
                                             placeholder="First Name" required/>
                                        </div>                               
                                    </div>

                                    <div className="col-lg-6">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input type="text"
                                             name="email"
                                             value={email}
                                             onChange={e => setEmail(e.target.value)}
                                             placeholder="Eamil" required/>
                                        </div>
                                    </div>		
         
                                    <div className="col-lg-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input type="text" 
                                            id="subject"
                                            name="subject"
                                            value={subject}
                                            onChange={e => setSubject(e.target.value)}
                                              placeholder="Subject"/>
                                        </div>
                                    </div>	
                                    <div className="col-lg-12">
                                        <div className="contact-field p-relative c-message mb-30">                                  
                                            <textarea id="message"
                                              name="message"
                                              value={message}
                                              onChange={e => setMessage(e.target.value)}
                                             cols="30"
                                              rows="10"
                                               placeholder="Write comments"></textarea>
                                        </div>
                                        <div className="slider-btn">                                          
                                                    <button
                                                     className="btn ss-btn" 
                                                     data-animation="fadeInRight"
                                                      data-delay=".8s"
                                                      disabled={isLoading?true:false}
                                                      ><span>Submit Now</span></button>				
                                                </div>                             
                                    </div>
                                    </div>
                            </form>                            
                            </div>    
                        
						</div>
					</div>
                    
                </div>
               
            </section>

            <div className="map fix" style={{background: "#f5f5f5"}}>
                <div className="container-flud">
                    
                    <div className="row">
                        <div className="col-lg-12">
                       {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212867.83634504632!2d-112.24455686962897!3d33.52582710700138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b743829374b03%3A0xabaac255b1e43fbe!2sPlexus%20Worldwide!5e0!3m2!1sen!2sin!4v1618567685329!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe> */}
                       <iframe 
                            src={contact && contact.site_map ? contact.site_map : ""}
                        style={{border:"0"}} allowFullScreen="" 
                            
                            width="600" height="450" loading="lazy"
                            
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <BookAppointmentComponent/>
   </>
  )
}

export default ContactPage