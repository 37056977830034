import axios from "axios";
import React, { useEffect, useState } from "react";
import BannerComponent from "../Components/Common/BannerComponent";
import BookAppointmentComponent from "../Components/Common/BookAppointmentComponent";
import { imgUrl, linkUrl } from "../utilies/linkUrl";

const TeamPage = () => {
  const [teams, setTeams] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const TeamsData = async () => {
      setIsLoading(true);
      const teams_data = await axios.get(`${linkUrl}/alldoctors`);
      if (teams_data.data.status === 200) {
        setTeams(teams_data.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    TeamsData();
  }, []);
  return (
    <>
      <BannerComponent title="Our Team" subTitle="Team" />
      <section className="team-area fix p-relative pt-120 pb-90">
        <div className="container">
          <div className="row">
            {teams &&
              teams.length > 0 &&
              teams.map((t, idx) => (
                <div className="col-lg-3 col-md-4 mb-4 col-sm-6" key={idx}>
                  <div className="single-team  h-100">
                    <div className="team-thumb">
                      <div className="brd">
                        {t.doc_img ? (
                          <img
                            className="img-fluid"
                            src={`${imgUrl}${t.doc_img}`}
                            alt=""
                          />
                        ) : (
                          <img src="assets/img/team/team01.png" alt="img" />
                        )}
                      </div>{" "}
                    </div>
                    <div className="team-info">
                      <h4>
                      {t.doc_name?t.doc_name:""}
                      </h4>
                     <p>{t.position?t.position:""}</p> 
                     <p>{t.department?t.department:""}</p> 
                     {
                                t.nmc &&
                            <p className="text-primary">{`N.M.C.No: ${t.nmc?t.nmc:""}`}</p>

                            }
                
                    </div>
                  </div>
                </div>
              ))}


          </div>
        </div>
      </section>

      <BookAppointmentComponent />
    </>
  );
};

export default TeamPage;
