import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { linkUrl } from '../../utilies/linkUrl';

const SliderHomeComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const sliderData= async()=>{
      setIsLoading(true);
      const res = await axios.get(`${linkUrl}/slider`);
      if (res.data.status === 200) {
          setData(res.data.data) ;
          setIsLoading(false);
      }
      else{
          setIsLoading(false);
      }

  };
  sliderData()


  },[])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <section id="home" className="slider-area slider-four fix p-relative">
       <Slider {...settings}>
       {
            data && data.sliders.length > 0 && data.imgurl &&
            data.sliders.slice(0,3).map((s,idx)=>(
              <div className="slider-active" style={{Background: "#00173c"}} key={idx}>
              <div>
              <img src={`${data.imgurl}/${s.slider_img}`} className='img-fluid custom-slide' width="100%" height="50%" alt='' />
      
              </div>
           
              </div>

            ))
        
       }

        
        </Slider>         

        
   
</section>
  )
}

export default SliderHomeComponent