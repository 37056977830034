import React from 'react'
import { Link } from 'react-router-dom'

const BookAppointmentComponent = () => {
  return (
    <>
                <div className="call-area" style={{backgroundImage:"url(assets/img/bg/call-bg.png)", backgroundRepeat: "no-repeat",backgroundPosition: "bottom"}}>
                <div className="container">
                    <div className="row align-items-center">
                         <div className="col-lg-5 col-md-5 col-sm-12">
                             <div className="single-counter-img fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
								 <img src="assets/img/bg/call-man.png" alt="img" className="img"/>
                            </div>
                        </div>
                      <div className="col-lg-7 col-md-7 col-sm-12">        
                          <div className="call-text">
                              <h5>Book Dentail Appointment</h5>
                                <h2>We Are open And Welcoming Patients</h2>
                                <Link to="/appointment" className="btn">Book Appointment</Link>
                          </div>
                       </div>
                       
                    </div>
                </div>
            </div>
    
    </>
  )
}

export default BookAppointmentComponent