import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import aboutService from "../../services/aboutServices";
import { imgUrl } from "../../utilies/linkUrl";

const AboutUsHomeComponent = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);
  return (
    <section className="about-area about-p pb-120 p-relative fix">
     
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {about && about.about_img && (
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img
                  src={about.about_img ? `${imgUrl}${about.about_img}` : ""}
                  style={{ objectFit: "cover" }}
                  alt="img"
                />

              </div>
            )}
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div
              className="about-content s-about-content  wow fadeInRight  animated"
              data-animation="fadeInRight"
              data-delay=".4s"
            >
              <div className="about-title second-title pb-25">
                {/* <h5>About Us</h5> */}
                {about && about.about_title && (
                  <h2>{about.about_title ? about.about_title : ""}</h2>
                )}
              </div>

              {about && about.about_des && (
                <>
                  <p
                    className="mb-4"
                    dangerouslySetInnerHTML={{
                      __html: about.about_des.slice(0, 500) + ".......",
                    }}
                  ></p>
                </>
              )}

              <div className="slider-btn mt-20">
                <Link to="/about-us" className="btn ss-btn smoth-scroll">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHomeComponent;
