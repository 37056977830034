import React, { useEffect } from 'react'
import AppointmentComponent from '../Components/Common/AppointmentComponent'
import AboutUsHomeComponent from '../Components/HomeComponent/AboutUsHomeComponent'
import ServiceHomeComponent from '../Components/HomeComponent/ServiceHomeComponent'
import SliderHomeComponent from '../Components/HomeComponent/SliderHomeComponent'

const HomePage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>

   <SliderHomeComponent/>
   <ServiceHomeComponent/>
   <AboutUsHomeComponent/>
   <AppointmentComponent/>

    </>
  )
}

export default HomePage