import React, { useEffect, useState } from "react";
import servicDataService from "../../services/ourServices";
import { imgUrl } from "../../utilies/linkUrl";

const ServiceHomeComponent = () => {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const servicesData = async () => {
      setIsLoading(true);
      const service = await servicDataService();
      if (service) {
        setServices(service);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    servicesData();
  }, []);
  return (
    <section
      id="service-details2"
      className="service-details-two pt-120 pb-105 p-relative"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="section-title center-align mb-50 text-center">
              <h5>Our Services</h5>
              <h2>What We Provide</h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="about-content s-about-content">
              <ul className="sr-tw-ul text-right">
                {services &&
                  services.length > 0 &&
                  services.slice(0, 3).map((service, idx) => (
                    <li key={idx}>
                      <div className="text text-start">
                        <h4>
                          {service.service_name ? service.service_name : ""}
                        </h4>
                      </div>
                      <div className="icon-right" style={{ width: "200px" }}>
                        <img
                          src={`${imgUrl}${service.service_img}`}
                          style={{
                            width: "118px",
                            height: "66px",
                            objectFit: "cover",
                          }}
                          alt="icon01"
                        />
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
            <div className="sd-img">
              <img
                src="assets/img/features/services-img-details2.png"
                alt="img"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="about-content s-about-content">
              <ul className="sr-tw-ul">
                {services &&
                  services.length > 2 &&
                  services.slice(3, 6).map((service, idx) => (
                    <li key={idx}>
                      <div className="text text-start">
                        <h4>
                          {service.service_name ? service.service_name : ""}
                        </h4>
                      </div>
                      <div className="icon-right" style={{ width: "200px" }}>
                        <img
                          src={`${imgUrl}${service.service_img}`}
                          style={{
                            width: "118px",
                            height: "66px",
                            objectFit: "cover",
                          }}
                          alt="icon01"
                        />
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceHomeComponent;
