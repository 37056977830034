import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import siteSetting from '../../services/settingServices';
import { imgUrl } from '../../utilies/linkUrl';

const FooterComponent = () => {
    const [contact, setContact] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
    
        const settingData= async()=>{
            setIsLoading(true);
            const sitedata = await siteSetting()
            if(sitedata) {
                setContact(sitedata) ;
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }

        }
        settingData()
    },[])
  return (
    <footer className="footer-bg footer-p pt-90" style={{background: "#00173c"}}>
      
            <div className="footer-top pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                            {contact && contact.site_logo &&
                                 <div className="logo">
                                 <h2>
                                    <img  className="img-fluid" src={contact.site_logo ? `${imgUrl}${contact.site_logo}` : ""} alt="logo"/>
                                    </h2>
                             </div>

}

                    
                               <div className="footer-link">
                                <p   style={{ color: ' #00b3f0' }}>{contact && contact.site_title ? contact.site_title : ""}</p>
                                   <div className="f-contact">
                                    <ul>
                                        <li>
                                            <i className="icon fal fa-clock"></i>
                                            <span>Sunday - Friday:<br/>9:00am - 6:00pm</span>
                                            
                                        </li>
                                        <li>
                                            <i className="icon fal fa-clock"></i>
                                            <span>Saturday:<br/>9:00am - 2:00Pm</span>
                                            
                                        </li>
                                    </ul>
                                    
                                </div>
                                   
                                    
                                </div>
                            </div>
                        </div>
						
                        <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Other Links</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>
                                        <li><Link to="/"> Home</Link></li>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/services">Services</Link></li>
                                    
                                        <li><Link to="/teams">Our Team</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>

                       
                                    </ul>
                                </div>
                            </div>
                        </div>  
     
                        <div className="col-xl-3 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="f-contact">
                                    <ul>
                                        {
                                             contact &&
                                             <>
                                                       <li>
                                            <i className="icon fal fa-map-marker-check mt-0"></i>
                                            <span>{contact.site_address ? contact.site_address : ""}</span>
                                        </li>
                                        <li>
                                            <i className="icon fal fa-phone mt-0"></i>
                                            <span>{contact.site_phone ? contact.site_phone : ""}<br/>{contact.site_mobile ? contact.site_mobile : ""}</span>
                                        </li>
                                       <li><i className="icon fal fa-envelope mt-0"></i>
                                            <span>
                                                <a href={`tel:${contact.site_email ? contact.site_email : ""}`}> {contact.site_email ? contact.site_email : ""}</a>
                                  
                                          
                                           </span>
                                        </li>
                                             </>
                                             
                                        }
                              
                                        
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                          <div className="copy-text">
                                 Copyright &copy;  {contact && contact.site_title ? contact.site_title : ""}. All rights reserved.|  
                                 Developed By BLACKHAWK TEAM     
                            </div>
                        </div>
             
                        <div className="col-lg-4">
                            <div className="footer-social">  
                            {
                                   contact && contact.site_fb_link &&
                                   <a href={`${contact.site_fb_link ? contact.site_fb_link : ""}`} target="_blank"><i className="fab fa-facebook-f"></i></a>
                            }  
                              {
                                   contact && contact.site_lin_link &&
                                   <a href={`${contact.site_lin_link ? contact.site_lin_link : ""}`} target="_blank"><i className="fab fa-linkedin-in"></i></a>
                            }  
                                 {
                                   contact && contact.site_tw_link &&
                                   <a href={`${contact.site_tw_link ? contact.site_tw_link : ""}`} target="_blank"><i className="fab fa-twitter"></i></a>
                            }     
                                                             {
                                   contact && contact.site_in_link &&
                                   <a href={`${contact.site_in_link ? contact.site_in_link : ""}`} target="_blank"><i className="fab fa-instagram"></i></a>
                            }                      

                                </div>    
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default FooterComponent